
  <template>
  <b-container v-if="planDetails.cancelled === false" fluid class="account-headers accounts-tabs-height">
    <b-row>
      <b-col cols="1" class="text-center">
        <b-img src="/img/account-icons.svg"></b-img>
      </b-col>
      <b-col cols="11">
        <b-row>
          <b-col cols="12">
            <h2>{{$t("profile.infotexts.PaymentDetails-title")}}</h2>
            <p>
              {{$t("profile.infotexts.PaymentDetails")}}
            </p>
            <hr />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    
    <b-row>
      <b-col cols="11" offset-md="1" class="account-sub-header pt-3 mb-5">
        <h3 class="mb-5">{{$t("profile.contents.plans&billing.payment-details.title")}}</h3>

        <p
          class="payment-details-p"
          v-if="planDetails.next_payment.date != null"
        >
          {{$t("profile.contents.plans&billing.payment-details.span1")}}
          <span>{{ planDetails.next_payment.date }}</span> {{$t("profile.contents.plans&billing.payment-details.span2")}}
          <span
            >{{ planDetails.next_payment.currency }}
            {{ planDetails.next_payment.amount }}
          </span>
        </p>
      </b-col>
    </b-row>
    <b-row class="payment-detail-card">
      <b-col cols="11" offset-md="1">
        <div class="row">
          <div class="col-md-6">
            <div class="card-holder">
              <div class="row">
                <div class="col-md-8">
                  <h2>{{ userMeInfo.name }} {{ userMeInfo.surname }}</h2>
                  <h3>
                    .... .... ....
                    <span>{{
                      planDetails.payment_information.last_four_digits
                    }}</span>
                  </h3>
                  <h4>{{ planDetails.payment_information.expiry_date }}</h4>
                </div>
                <div class="col-md-4 text-right credit-card-img">
                  <img
                    v-if="planDetails.payment_information.card_type == 'master'"
                    src="/img/card-master.svg"
                    alt=""
                    class="img-fluid"
                  />
                  <img
                    v-else-if="
                      planDetails.payment_information.card_type == 'visa'
                    "
                    src="/img/card-visa.svg"
                    alt=""
                    class="img-fluid"
                  />
                  <img
                    v-else-if="
                      planDetails.payment_information.card_type ==
                      'american_express'
                    "
                    src="/img/card-amex.svg"
                    alt=""
                    class="img-fluid"
                  />
                  <img
                    v-else-if="
                      planDetails.payment_information.card_type == 'paypal'
                    "
                    src="/img/card-paypal.svg"
                    alt=""
                    class="img-fluid"
                  />
                  <img
                    v-else
                    src="/img/card-paypal.svg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6">
                <h5>{{$t("profile.contents.plans&billing.payment-details.method")}}</h5>
                <h5>{{$t("profile.contents.plans&billing.payment-details.last")}}</h5>
              </div>
              <div class="col-md-6">
                <h6>{{ planDetails.payment_information.payment_method }}</h6>
                <h6>{{ planDetails.last_payment.date }}</h6>
              </div>
              <div class="col-md-12 mt-3">
                <a
                  @click="changePayment()"
                  class="change-method-bttn text-right"
                  >{{$t("buttons.changemet")}}</a
                >
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="11" offset-md="1" class="account-sub-header text-right pt-3">
        <hr style="margin-top: 36px !important" class="mt-3" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="11" offset-md="1" class="account-sub-header pt-3">
        <iframe
          v-if="getChangeUrl"
          :src="getChangeUrl"
          frameborder="0"
          width="100%"
          height="750px"
        ></iframe>
        <!-- <p class="change-redirect">
          {{$t("profile.contents.plans&billing.payment-details.infotext")}}
        </p> -->
      </b-col>
    </b-row>
  </b-container>
  <b-container v-else class="account-headers accounts-tabs-height">
    <b-row>
        <b-col cols="11" offset="1">
            <div class="text-center quotes">
                <div class="dont-have-active-passive">
                    <div class="row">
                        <div class="col-md-12">
                            <h6> {{$t("profile.contents.plans&billing.payment-details.noplan-text")}}</h6>
                        </div>
                        <div class="col-md-12 text-right">
                            <img  style="margin-top:150px" src="/img/dont-have.svg" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </b-col>
    </b-row>
  </b-container>
</template>

<script>
import changePayment from "@/graphql/me/profile/changePayMethod.gql";
import getMyPlan from "@/graphql/me/profile/getMyPlan.graphql";

export default {
  data() {
    return {
      copyLink: null,
      getChangeUrl: null,
      planDetails: null,
      userMeInfo: [],
    };
  },
  methods: {
    async changePayment() {
      try {
        let data = await this.$apollo.mutate(changePayment, {});
        let response = data("**.update");

        this.getChangeUrl = response;
        console.log(response);
      } catch (e) {
        console.log(e);
      }
    },

    async getMyPlanDetail() {
      try {
        let data = await this.$apollo.query(getMyPlan, {});
        let response = data("**.get");

        this.planDetails = response;
      } catch (e) {
        console.log(e);
      }
    },
  },
  created() {
    this.getMyPlanDetail();
    let userInfo = this.GET_USER_INFO;
    this.userMeInfo = userInfo;
  },
};
</script>